import React from 'react';
import { 
    Box,
    Link, 
    useDisclosure,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { FaXTwitter, FaDiscord, FaTelegram } from 'react-icons/fa6';
import { HamburgerIcon } from '@chakra-ui/icons';
import { IconContext } from 'react-icons/lib';
import styled from 'styled-components';

const Hidden = styled(Box)`
  display: none;

  @media only screen and (max-width: 780px) {
    display: block;
    z-index: 999;
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  row-gap: 1rem;
`;

const MobileMenu = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Hidden>
      <Box as="button" onClick={onOpen}>
        <HamburgerIcon w={6} h={6} color={props.light ? "#000" : "#fff"} />
      </Box>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="fullscreen">
        <DrawerOverlay />
        <DrawerContent bg="#fff">
        <DrawerCloseButton style={{color: '#303030' }} _focus={{ outline: 'none' }} _hover={{ filter: 'brightness(65%)' }} />
        <DrawerBody>
          <Flexbox>
            <Link
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
            >
              Markets (Coming soon!)
            </Link>
            <Link
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
              href="https://app.karak.network"
              isExternal
            >
              Stake
            </Link>
            <Link
              as={RouteLink}
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
              to="/ecosystem"
            >
              Ecosystem
            </Link>
            <Link
              as={RouteLink}
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
              to="/bridge"
            >
              K2 Bridge
            </Link>
            <Link 
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
              href="https://airtable.com/appomORhKwfeQrCIk/shrla3jWOjs7fId8j"
              isExternal
            >
              Build (Get in touch)
            </Link>
            <Link 
              color="#303030"
              fontSize="1rem"
              fontWeight="700"
              fontFamily="Nunito Sans"
              _focus={{ outline: 'none !important'}}
              href="https://docs.karak.network"
              isExternal
            >
              Docs
            </Link>
            <IconContext.Provider
              value={{ color: '#303030', size: '1.5rem', style: { cursor: 'pointer' } }}
            >
              <FaXTwitter onClick={() => { window.open('https://twitter.com/Karak_Network')}} />
              <FaDiscord onClick={() => { window.open('https://discord.com/invite/karak')}} />
              <FaTelegram onClick={() => { window.open('https://t.me/Karak_Network')}} />
            </IconContext.Provider>
          </Flexbox>
        </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Hidden>
  );
}

export default MobileMenu;
