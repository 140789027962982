import './App.css';
import { ThemeProvider, CSSReset, theme } from '@chakra-ui/react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Details from './components/Details/Details';
import GlobalTrust from './components/GlobalTrust/GlobalTrust';
import SharedSecurity from './components/SharedSecurity/SharedSecurity';
import GlobalBaseLayer from './components/GlobalBaseLayer/GlobalBaseLayer';
import KarakNetworkStack from './components/KarakNetworkStack/KarakNetworkStack';
import ExploreEcosystem from './components/ExploreEcosystem/ExploreEcosystem';
import GetStarted from './components/GetStarted/GetStarted';
import Ecosystem from './components/Ecosystem/Ecosystem';
import { Bridge } from './components/Karak-XP/Bridge';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import TermsOfService from './components/TermsOfService/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Investors from './components/Investors/Investors';
import Analytics from './analytics';
const Main = styled.div`
`;

const Container = styled.div`
  position: relative;
`;

const Blur = styled.div`
  position: absolute;
  top: 4.5%;
  width: 100vw;
  height: 30vh;
  opacity: 0.6;
  background: var(--components-primary-orange);
  filter: blur(205px);
  z-index: 0 !important;

  @media only screen and (max-width: 849px) {
    top: 3%;
  }

  @media only screen and (max-width: 598px) {
    top: 2.5%;
  }
`;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Main className="main">
          <Router>
            <Analytics />
            <Switch>
              <Route exact path="/">
                <Blur />
                <Container className="container">
                  <Header light />
                  <Hero />
                  <Details />
                  <GlobalTrust />
                  <GlobalBaseLayer />
                  <SharedSecurity />
                  <KarakNetworkStack />
                  <ExploreEcosystem />
                  <GetStarted />
                  <Investors />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/bridge">
                <Container className="container">
                  <Header light />
                  <Bridge />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/about">
                <Container className="container">
                  <Header light />
                  <About />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/ecosystem">
                <Container className="container">
                  <Header light />
                  <Ecosystem />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/terms-of-service">
                <Container className="container">
                  <Header light />
                  <TermsOfService />
                  <Footer />
                </Container>
              </Route>
              <Route to path="/privacy-policy">
                <Container className="container">
                  <Header light />
                  <PrivacyPolicy />
                  <Footer />
                </Container>
              </Route>
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        </Main>
      </ThemeProvider>
    </>
  );
}

export default App;
