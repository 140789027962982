import React from "react";
import styled from "styled-components";
import layers from "../../assets/global-base-layer.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: -0.96px;
  z-index: 999;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  text-align: center;
  color: #303030;
  font-family: Nunito Sans;
  font-size: var(--p-font-size);
  z-index: 999;
  margin-bottom: 1.5rem;
  width: 50rem;

  @media only screen and (max-width: 867px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 40rem;
`;

const GlobalBaseLayer = () => {
  return (
    <Container>
      <HeadingTitle>Global Base Layer</HeadingTitle>
      <Text>
        Karak is building a global base layer for everyone, from indie developers to nation states, enabling them to create secure,
        specialized blockchain networks, optimized for internet markets. Karak enables nation states to bring entire
        financial markets onchain, connecting stocks, bonds, options, derivatives, and other financial instruments to global markets.
      </Text>
      <Image src={layers} alt="layers" />
    </Container>
  );
}

export default GlobalBaseLayer;