import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Box, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: start;
  max-width: 30rem;
  column-gap: 0rem;
  margin: 4rem 0rem;

  @media only screen and (max-width: 545px) {
    row-gap: 2rem;
    grid-template-columns: auto auto;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.2rem;
`;

const Title = styled.p`
  color: #656565;
  font-size: 0.85rem;
  font-weight: bold;
`;

const TextLink = styled(Link)`
  color: #303030 !important;
  font-size: 0.75rem !important;

  &:focus {
    outline: none;
  }
`;

const GridLink = () => {
  return (
    <GridBox>
      <FlexBox>
        <Title>Karak</Title>
        <TextLink href="https://app.karak.network" _focus={{ outline: 'none' }} isExternal>Markets (Coming soon!)</TextLink>
        <TextLink href="https://app.karak.network" _focus={{ outline: 'none' }} isExternal>Staking</TextLink>
        <RouteLink to="/ecosystem" style={{marginTop: -5}}>
          <TextLink _focus={{ outline: 'none' }}>Ecosystem</TextLink>
        </RouteLink>
        <RouteLink to="/bridge" style={{marginTop: -5}}>
          <TextLink _focus={{ outline: 'none' }}>K2 Bridge</TextLink>
        </RouteLink>
      </FlexBox>
      <FlexBox>
        <Title>Developers</Title>
        <TextLink href="https://airtable.com/appomORhKwfeQrCIk/shrla3jWOjs7fId8j" _focus={{ outline: 'none' }} isExternal>Build (Get in touch)</TextLink>
        <TextLink href="https://docs.karak.network" _focus={{ outline: 'none' }} isExternal>Docs</TextLink>
        {/* <TextLink href="https://docs.karak.network" _focus={{ outline: 'none' }} isExternal>GitHub</TextLink> */}
      </FlexBox>
      <FlexBox>
        <Title>Community</Title>
        <TextLink href="https://twitter.com/Karak_Network" _focus={{ outline: 'none' }} isExternal>X (Twitter)</TextLink>
        <TextLink href="https://discord.com/invite/karak" _focus={{ outline: 'none' }} isExternal>Discord</TextLink>
        <TextLink href="https://t.me/Karak_Network" _focus={{ outline: 'none' }} isExternal>Telegram</TextLink>
      </FlexBox>
      <FlexBox>
        <Title>About</Title>
        <RouteLink to="/about" style={{marginTop: -5}}>
          <TextLink _focus={{ outline: 'none' }}>Foundation</TextLink>
        </RouteLink>
        <TextLink href="https://blog.karak.network" _focus={{ outline: 'none' }} isExternal>Blog</TextLink>
        <TextLink href="https://www.figma.com/file/jpfomnFsJMyZ2oByt7EZvg/Karak-Brand" _focus={{ outline: 'none' }} isExternal>Brand</TextLink>
      </FlexBox>
    </GridBox>
  );
}

export default GridLink;