import React from 'react';
import { Button, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 4rem 0rem;
  margin: auto;
  margin-bottom: 6rem;
  z-index: 999;
`;

const ColoredContainer = styled.div`
  position: absolute;
  margin-left: calc(50% - 50vw);
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  border-top: 0.2px solid #36363650;
  border-bottom: 0.2px solid #36363650;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  letter-spacing: -0.96px;
  line-height: 1.15;
  z-index: 999;
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  text-align: center;
  color: #303030;
  font-family: Nunito Sans;
  font-size: var(--p-font-size);
  z-index: 999;
  margin-bottom: 2.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

const SubButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const GetStarted = () => {
  return (
    <Container>
      <ColoredContainer />
      <HeadingTitle>Get started</HeadingTitle>
      <Text>Everything you need to get started on Karak.</Text>
      <ButtonContainer>
        <SubButtonContainer>
          <Button
            as={Link}
            href="https://app.karak.network"
            isExternal
            _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: 'none'}}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-orange)"
            fontSize="0.9rem"
            borderRadius="5px"
            width="10rem"
            fontFamily="Nunito Sans"
            fontWeight="700"
          >
            Stake now
          </Button>
          <Button
            as={Link}
            href="https://docs.karak.network"
            isExternal
            _hover={{textDecoration: 'none', filter: 'brightness(95%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)'}}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="var(--components-primary-orange)"
            target="_new"
            background="white"
            boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.10)"
            fontSize="0.9rem"
            borderRadius="5px"
            width="10rem"
            fontFamily="Nunito Sans"
            fontWeight="700"
          >
            Learn more
          </Button>
        </SubButtonContainer>
      </ButtonContainer>
    </Container>
  );
}

export default GetStarted;
