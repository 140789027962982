import React from "react";
import styled from "styled-components";
import { Button, Link } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import usdc from "../../assets/restaking-assets/usdc.svg";
import lido from "../../assets/restaking-assets/lido.svg";
import wbtc from "../../assets/restaking-assets/wbtc.svg";
import usdt from "../../assets/restaking-assets/usdt.svg";
import sDAI from "../../assets/restaking-assets/sDAI.svg";
import cbETH from "../../assets/restaking-assets/cbETH.svg";
import wBETH from "../../assets/restaking-assets/wbETH.svg";
import infinity from "../../assets/restaking-assets/karak-infinity.svg";

const Container = styled.div`
  margin-bottom: 6rem;
`;

const HeadingTitle = styled.h1`
  text-align: center;
  color: #303030;
  font-size: 2.5rem;
  font-family: Gilroy;
  font-weight: 800;
  letter-spacing: -0.96px;
  line-height: 1.15;
  z-index: 999;
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  text-align: center;
  color: #303030;
  font-family: Nunito Sans;
  font-size: var(--p-font-size);
  z-index: 999;
  margin: auto auto 1.5rem auto;
  width: 50rem;

  @media only screen and (max-width: 867px) {
    width: 100%;
  }
`;

const RestakingExamples = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: auto;
  gap: 1rem;
  max-width: 80%;

  @media only screen and (max-width: 803px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const SDiv = styled.div`
  border-radius: 3.629px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
  padding: 2rem 1.5rem;
`;

const AssetDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
`;

const AssetImg = styled.img`
  width: 2rem;
`;

const AssetName = styled.p`
  color: #303030;
  text-align: center;
  font-family: Nunito Sans;
  font-weight: 700;
`;

const AssetSymbol = styled.div`
  border-radius: 5px;
  background: #FFF4ED;
  color: var(--components-primary-orange);
  padding: 0.25rem 0.5rem;
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 0.85rem;
  margin-left: auto;
`;

const RestakedDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
  padding: 1rem;
`;

const RestakedText = styled.p`
  color: #656565;
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 0.85rem;
`;

const ManyMoreDiv = styled.div`
  border-radius: 3.629px;
  border: 0.145px solid rgba(214, 90, 0, 0.85);
  background: linear-gradient(90deg, #DF5E00 0%, #FF9C54 100%);
  padding: 2rem 1.5rem;
`;

const pools = [
  {
    name: "USD Coin",
    src: usdc,
    alt: "usdc",
    symbol: "USDC",
    amount: "83,162,655.24"
  },
  {
    name: "Lido Staked Ether",
    src: lido,
    alt: "lido-st-eth",
    symbol: "stETH",
    amount: "321,234.31"
  },
  {
    name: "Wrapped Bitcoin",
    src: wbtc,
    alt: "wbtc",
    symbol: "wBTC",
    amount: "1,951.88"
  },
  {
    name: "Tether USD",
    src: usdt,
    alt: "usdt",
    symbol: "USDT",
    amount: "97,725,832.97"
  },
  {
    name: "Savings DAI",
    src: sDAI,
    alt: "sDAI",
    symbol: "sDAI",
    amount: "51,457,476.68"
  },
  {
    name: "Coinbase Ether",
    src: cbETH,
    alt: "coinbase-eth",
    symbol: "cbETH",
    amount: "47,103.25"
  },
  {
    name: "Binance Ether",
    src: wBETH,
    alt: "binance-eth",
    symbol: "wBETH",
    amount: "98,018.12",
  },
];

const SharedSecurity = () => {
  return (
    <Container>
      <HeadingTitle>Turnkey shared security</HeadingTitle>
      <Text>
        Karak introduces multiasset (re)staking, a new primitive in cryptoeconomic security
        allowing users to provide shared security in exchange for boosted rewards. Multiasset
        (re)staking also supercharges security for blockchains, protocols, and other decentralized
        infrastructure layers.
      </Text>
      <RestakingExamples>
        {pools.map((pool, index) => {
          return (
            <SDiv key={index}>
              <AssetDiv>
                <AssetImg src={pool.src} alt={pool.alt} />
                <AssetName>{pool.name}</AssetName>
                <AssetSymbol>{pool.symbol}</AssetSymbol>
              </AssetDiv>
              <RestakedDiv>
                <RestakedText>RESTAKED</RestakedText>
                <RestakedText style={{ color: '#303030', marginLeft: 'auto', fontSize: '1rem' }}>
                  {pool.amount}
                </RestakedText>
              </RestakedDiv>
            </SDiv>
          )
        })}
        <ManyMoreDiv>
          <AssetDiv>
            <AssetImg src={infinity} alt="" />
            <AssetName style={{ fontFamily: "Inter", color: "white" }}>And many more</AssetName>
          </AssetDiv>
          <Button
              as={Link}
              href="https://app.karak.network"
              isExternal
              _hover={{textDecoration: 'none', filter: 'brightness(90%)'}}
              _active={{color: 'transparent'}}
              _focus={{outline: 'none', boxShadow: 'none'}}
              px="var(--button-px)"
              py="var(--button-py)"
              textColor="white"
              border="0.2px solid #FFF"
              background="rgba(255, 255, 255, 0.28)"
              fontSize="0.85rem"
              borderRadius="5px"
              fontFamily="Inter"
              fontWeight="700"
              marginTop="0.5rem"
              width="9rem"
            >
              Get started
              <ArrowForwardIcon boxSize={5} ml={"0.25rem"} />
            </Button>
        </ManyMoreDiv>
      </RestakingExamples>
    </Container>
  );
}

export default SharedSecurity;
