import React from 'react';
import { Box } from '@chakra-ui/react';
import stakers from '../../assets/details/multiasset.png';
import operators from '../../assets/details/crosschain.png';
import builders from '../../assets/details/developers.png';
import styled from 'styled-components';

const Container = styled(Box)`
  margin-bottom: 5rem;
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  column-gap: 1.5rem;

  @media only screen and (max-width: 872px) {
    column-gap: 0rem;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

const ItemBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;

  padding: 0rem 1.5rem;
  width: 31%;

  @media only screen and (max-width: 872px) {
    width: 25rem;
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
  }
`;

const Image = styled.img`
  height: 5rem;
  margin-left: -5px;
`;

const Title = styled.h2`
  background: linear-gradient(90deg, #DF5E00 0%, #FF6B00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: Inter;
  font-size: 1.5rem;
`;

const Desc = styled.p`
  color: #303030;
  font-size: var(--p2-font-size);
  font-weight: 500;
  font-family: Inter;
  max-width: 95%;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1368px) {
    max-width: 100%;
  }
`;

const data = [
  {
    title: "Builders",
    desc: "Karak enables anyone to launch specialized networks with no inflation by leveraging shared security and instant, robust validator sets to ensure proper network operations.",
    src: builders,
    alt: "builders"
  },
  {
    title: "Stakers",
    desc: "Karak makes it easy to provide cryptoeconomic security to specialized networks with any asset, with the ability to earn rewards on any asset by leveraging advanced staking strategies.",
    src: stakers,
    alt: "stakers"
  },
  {
    title: "Operators",
    desc: "Karak allows for node operators to run validation software on Karak to secure more networks with more assets, enabling maximal capital efficiency with minimal overhead.",
    src: operators,
    alt: "operators"
  }
];

const Details = () => {
  return (
    <Container>
      <FlexBox>
        {data.map((item, index) =>
          <ItemBox key={index}>
            <Image src={item.src} alt={item.alt} />
            <Title>{item.title}</Title>
            <Desc>{item.desc}</Desc>
          </ItemBox>
        )}
      </FlexBox>
    </Container>
  );
}

export default Details;
